export const useSnackBarMessages = () =>
  useState(
    'snackBarMessages',
    (): { id?: number; type: string; result: string; text: string }[] => []
  )

export const useSnackBarFlashMessages = () =>
  useState(
    'snackBarFlashMessages',
    (): { id?: number; type: string; result: string; text: string }[] => []
  )

export default function useSnackBar() {
  const DEFAULT_TIMEOUT = 3000
  const messages = useSnackBarMessages()
  const flashMessage = useSnackBarFlashMessages()

  let counter = 0

  const addFlashMessage = (
    message: { id?: number; type: string; result: string; text: string },
    timeout: number
  ) => {
    flashMessage.value.push(message)
  }

  const getFlashMessage = () => {
    const message = flashMessage.value[0]
    if (message) {
      flashMessage.value.splice(0, 1)
    }
    return message
  }

  const addMessage = (
    message: { id?: number; type: string; result: string; text: string },
    timeout: number
  ) => {
    message.id = ++counter
    messages.value.push(message)

    setTimeout(() => {
      removeMessage(message.id)
    }, timeout || DEFAULT_TIMEOUT)
  }

  const removeMessage = (id: number) => {
    const index = messages.value.findIndex((n) => n.id === id)
    if (index != -1) messages.value.splice(index, 1)
  }

  return {
    addMessage,
    removeMessage,
    addFlashMessage,
    getFlashMessage,
  }
}
